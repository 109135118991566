import { useRouter } from 'next/router'
import React, { useContext, useState, useEffect } from 'react'
import Context from '../context'
import Link from 'next/link'
import Image from 'next/image'
import { thumbLoader } from '../utils/custom-image-loader'
import AddToCart from './AddToCart'

const MiniProductCard = ({ data }) => {
  const inStoreOnly = data.in_store_only === true || data.in_store_only === 'true'
  const { state } = useContext(Context)
  const brokenImage = '/images/popshelf-broken-onwhite.svg'
  const [imageSrc, setImageSrc] = useState(data.image_urls?.split(',')[0] || brokenImage)
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const router = useRouter()

  const getQuantity = () => {
    return state.cart?.products?.find(product => +data.upc === +product.upc)?.quantity || 0
  }

  const setButtons = () => {
    if (data.variants?.length) {
      return (
        <button
          className="btn__secondary"
          key={data.upc}
          onClick={(e) => {
            e.preventDefault()
            router.push(`/p/${data.slug}`)
          }}
        >
          View Options
        </button>
      )
    } else if (data.in_stock && !inStoreOnly && parseFloat(data?.price || 0)?.toFixed(2) !== '0.01' && parseFloat(data?.final_price || 0)?.toFixed(2) !== '0.01') {
      return <AddToCart product={data} cartQuantity={getQuantity()} />
    } else {
      return (
        <button
          className="btn__secondary"
          key={data.upc}
          onClick={(e) => {
            e.preventDefault()
            router.push(`/p/${data.slug}`)
          }}
        >
          View details
        </button>
      )
    }
  }

  useEffect(() => {
    if (tooltipOpen && !state.tooltipOpen) {
      setTooltipOpen(false)
    }
  }, [state.tooltipOpen])

  return (
    <div className='mini-product'>
      <Link href="/p/[slug]" as={`/p/${data.slug}`}>
        <a
          s_objectid={`${data.digital_product_name || data.product_name} | ${data.sku || 'N/A'} | ${data.campaign_name} | ${data.date_reference}`}
          aria-label={`${data.digital_product_name || data.product_name}, ${data.avg_rating} stars, ${(data.discount_price || data.price) &&
            parseFloat(data.discount_price ? data.discount_price : data.price) &&
            `$${parseFloat(data.discount_price ? data.discount_price : data.price).toFixed(2)}`
            } ${data.inventory || 0} in stock`}
        >
          <div className="mini-product-card">
            <div className="mini-product-card__details">
              <div className="mini-product-card__details-image">
                <Image
                  src={imageSrc}
                  alt={data.digital_product_name || data.product_name}
                  layout="fill"
                  loader={thumbLoader}
                  onError={() => setImageSrc(brokenImage)}
                />
              </div>
              <div className="mini-product-card__details__product-name">
                <p>{data.digital_product_name || data.product_name}</p>
              </div>
            </div>
            <div className="mini-product-card__availability">
              <div className="mini-product-card__availability-price">
                <p hidden={isNaN(data.price) || data.price === 0}>{`$${parseFloat(data.discount_price ? data.discount_price : data.price)?.toFixed(2)}`}</p>
              </div>
            </div>
            <div className="mini-product-card__availability-setbutton">{setButtons()}</div>
          </div>
        </a>
      </Link>
    </div>
  )
}

export default MiniProductCard
