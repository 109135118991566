import React from 'react'
import CardArticle from './CardArticle'
import ProductCard from './ProductCard'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Pagination, FreeMode, Navigation, SwiperOptions } from 'swiper'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/keyboard'
import FourPanelCard from './FourPanelCard'
import MiniProductCard from './MiniProductCard'
import CircleCardOutlined from './CircleCardOutlined'

interface ContentCarouselProps {
  data: any
  index?: string
  display?: string
}

const ContentCarousel = ({ data, index = '', display = 'horizontal' }: ContentCarouselProps) => {
  const swiperConfig: SwiperOptions = {
    modules: [FreeMode, Pagination, Navigation, Keyboard],
    freeMode: true,
    pagination: { clickable: true },
    navigation: {
      nextEl: `.swiper-button-next-home-${index}`,
      prevEl: `.swiper-button-prev-home-${index}`
    },
    spaceBetween: 16,
    keyboard: true,
    watchOverflow: true,
    allowTouchMove: display !== 'vertical',
    breakpoints: {
      512: {
        allowTouchMove: true
      }
    }
  }

  return (
    <section
      data-testid="content-carousel-container"
      className={`swiper__section content-carousel${display === 'vertical' ? ' content-carousel--vertical' : ''}`}
    >
      <div className={`swiper-button-prev-home-${index} swiper-navbutton swiper-navbutton-prev`}></div>
      <div className={`swiper-button-next-home-${index} swiper-navbutton swiper-navbutton-next`}></div>
      <Swiper key={index} {...swiperConfig} slidesPerView={'auto'}>
        {data.map((obj) => {
          const size = data.length
          switch (obj.type) {
            case 'product-card':
              return (
                <SwiperSlide key={`${obj.slug}-${index}`}>
                  <ProductCard data={obj} />
                </SwiperSlide>
              )
            case 'four-panel-card':
              return (
                <SwiperSlide
                  key={`${obj.title}-${index}`}
                  style={
                    size && { width: `calc(${100 / size}% - (16px * (${size - 1} / ${size})))`, minWidth: '260px' }
                  }
                >
                  <FourPanelCard data={obj} />
                </SwiperSlide>
              )
            case 'circle-card-outlined':
              return (
                <SwiperSlide key={`${obj.link}-${index}`}>
                  <CircleCardOutlined
                    title={obj.title}
                    image={obj.image}
                    alt={obj.alt}
                    link={obj.link}
                    dynamicMediaUrl={obj.dynamicMediaUrl}
                    dateReference={obj.date_reference}
                    campaignName={obj.campaign_name}
                  />
                </SwiperSlide>
              )
            case 'content-block':
              if (!('image_url' in obj) && !('image_urls' in obj)) {
                return <div data-testid="content-block-no-image"></div>
              }
              return (
                <SwiperSlide
                  key={`${obj.link_url}-${obj.title.replace(/\W+/g, '')}-${index}`}
                  style={
                    size && { width: `calc(${100 / size}% - (16px * (${size - 1} / ${size})))`, minWidth: '260px' }
                  }
                >
                  <CardArticle
                    title={obj.title}
                    image={obj.image_url}
                    dynamicMediaUrl={obj.dynamicMediaUrl}
                    handle={obj.link_url}
                    subtitle={obj.subtitle}
                    alt={obj.alt}
                    campaignName={obj.campaign_name}
                    dateReference={obj.date_reference}
                  />
                </SwiperSlide>
              )
            case 'mini-product-card':
              return (
                <SwiperSlide key={`${obj.slug}-${index}`}>
                  <MiniProductCard data={obj} />
                </SwiperSlide>
              )
            default:
              return <div data-testid="content-carousel-default" />
          }
        })}
      </Swiper>
    </section>
  )
}

export default ContentCarousel
