import { useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'

import { thumbLoader } from '../utils/custom-image-loader'

const CircleCardOutlined = ({ link, image, title, alt, dynamicMediaUrl, campaignName = null, dateReference = null }) => {
  const brokenImage = '/images/popshelf-broken-onwhite.svg'
  const dynamicImage = dynamicMediaUrl || image
  const [imageSrc, setImageSrc] = useState(dynamicImage || brokenImage)

  return (
    <Link href={link}>
      <a s_objectid={`${title} | N/A | ${campaignName} | ${dateReference}`} className="circle-card-focus" aria-label={title}>
        <div className="circle-card">
          <div className="circle-card-image-wrap">
            <div className="circle-card-image image-next">
              {imageSrc && (
                <Image
                  src={imageSrc}
                  alt={alt}
                  height={126}
                  width={126}
                  loader={thumbLoader}
                  layout="fixed"
                  aria-label={title}
                  onError={() => setImageSrc(brokenImage)}
                />
              )}
            </div>
            <div>
              <p>{title}</p>
            </div>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default CircleCardOutlined
