import { useState } from 'react'
import Link from 'next/link'
import Image from 'next/image'
import { mediumLoader } from '../utils/custom-image-loader'
import SVG from './SVG'

const CardArticle = ({
  title,
  image,
  dynamicMediaUrl = null,
  handle,
  subtitle,
  campaignName = null,
  dateReference = null,
  alt
}) => {
  const brokenImage = '/images/popshelf-broken-onwhite.svg'
  const dynamicImage = dynamicMediaUrl || image
  const [imageSrc, setImageSrc] = useState(dynamicImage || brokenImage)

  return (
    <Link href={`${handle?.toLowerCase()}`}>
      <a className="pop-card-article-focus" s_objectid={`${title} | N/A | ${campaignName} | ${dateReference}`} aria-label={`${title}, ${subtitle}`}>
        <div className="pop-card-article content-block__height-override">
          <div className='content-block__container'>
            <div className="pop-card-article__img">
              <Image
                src={imageSrc}
                alt={alt}
                layout="fill"
                objectFit="cover"
                loader={mediumLoader}
                onError={() => setImageSrc(brokenImage)}
              />
            </div>
            <div className="pop-card-article__header">
              <p className="pop-card-article__header-header">{title}</p>
              <p className="pop-card-article__header-subtitle">{subtitle}</p>
              <SVG name="arrow-right" label="Arrow-right" size="md" />
            </div>
          </div>
        </div>
      </a>
    </Link>
  )
}

export default CardArticle
