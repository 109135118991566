/* eslint-disable @next/next/no-img-element */
import { useState } from 'react'
import router from 'next/router'

const FourPanelCard = ({ data }) => {
  const brokenImage = '/images/popshelf-broken-onwhite.svg'
  const dynamicMediaUrl1 = data?.dynamicMediaUrls?.[1] || data?.images?.[1]
  const dynamicMediaUrl2 = data?.dynamicMediaUrls?.[2] || data?.images?.[2]
  const dynamicMediaUrl3 = data?.dynamicMediaUrls?.[3] || data?.images?.[3]
  const dynamicMediaUrl4 = data?.dynamicMediaUrls?.[4] || data?.images?.[4]
  const [image1, setImage1] = useState(dynamicMediaUrl1 || brokenImage)
  const [image2, setImage2] = useState(dynamicMediaUrl2 || brokenImage)
  const [image3, setImage3] = useState(dynamicMediaUrl3 || brokenImage)
  const [image4, setImage4] = useState(dynamicMediaUrl4 || brokenImage)

  return (
    <div id="four-panel" className="content-block__height-override">
      <div className="four-panel">
        <div
          className="four-panel-card"
          style={{ backgroundColor: data?.backgroundColors?.[1] }}
          s_objectid={`${data?.titles?.[1]} | N/A | ${data?.campaignName?.[1]} | ${data?.dateReferences?.[1]}`}
          onClick={() => router.push(`${data?.links?.[1]}`)}
        >
          <div className="four-panel-card__img">
            <img src={image1} alt={data?.alts?.[1]} onError={() => setImage1(brokenImage)} />
          </div>
          <div className="four-panel-card__title">
            <a href={data?.links?.[1]}>{data?.titles?.[1]}</a>
          </div>
        </div>
        <div
          className="four-panel-card"
          style={{ backgroundColor: data?.backgroundColors?.[2] }}
          s_objectid={`${data?.titles?.[2]} | N/A | ${data?.campaignName?.[2]} | ${data?.dateReferences?.[2]}`}
          onClick={() => router.push(`${data.links[2]}`)}
        >
          <div className="four-panel-card__img">
            <img src={image2} alt={data?.alts?.[2]} onError={() => setImage2(brokenImage)} />
          </div>
          <div className="four-panel-card__title">
            <a href={data?.links?.[2]}>{data?.titles?.[2]}</a>
          </div>
        </div>
        <div
          className="four-panel-card"
          style={{ backgroundColor: data?.backgroundColors?.[3] }}
          s_objectid={`${data?.titles?.[3]} | N/A | ${data?.campaignName?.[3]} | ${data?.dateReferences?.[3]}`}
          onClick={() => router.push(`${data?.links?.[3]}`)}
        >
          <div className="four-panel-card__img">
            <img src={image3} alt={data?.alts?.[3]} onError={() => setImage3(brokenImage)} />
          </div>
          <div className="four-panel-card__title">
            <a href={data?.links?.[3]}>{data?.titles?.[3]}</a>
          </div>
        </div>
        <div
          className="four-panel-card"
          style={{ backgroundColor: data?.backgroundColors[4] }}
          s_objectid={`${data?.titles?.[4]} | N/A | ${data?.campaignName?.[4]} | ${data?.dateReferences?.[4]}`}
          onClick={() => router.push(`${data.links[4]}`)}
        >
          <div className="four-panel-card__img">
            <img src={image4} alt={data?.alts?.[4]} onError={() => setImage4(brokenImage)} />
          </div>
          <div className="four-panel-card__title">
            <a href={data?.links?.[4]}>{data?.titles?.[4]}</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FourPanelCard
